import { useReducer, createContext, useMemo, useState, useEffect } from 'react'
import { Outlet } from "react-router-dom";
import BottomNavigator from "../components/BottomNavigator";
import SideBar from '../components/SideBar';
import HeaderLarge from '../components/HeaderLarge';
import FooterComponent from '../components/FooterComponent';
import ScrollToTop from '../components/ScrollToTop';
export const AuthContext = createContext();

export default function RootPage() {
  let mediaQueryList = window.matchMedia("(min-width: 992px)");
  const [isLargeDisplay, setisLargeDisplay] = useState(mediaQueryList.matches)
  useEffect(() => {
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener("change", checkDevice)
      return () => {
        mediaQueryList.removeEventListener("change", checkDevice)
      }
    } else {
      mediaQueryList.addListener(checkDevice)
      return () => {
        mediaQueryList.removeListener(checkDevice)
      }
    }

  }, [])
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'TOGGLE_DRAWER':
          return {
            ...prevState,
            openDrawer: !prevState.openDrawer
          };

      }
    },
    {
      openDrawer: false,

    }
  );
  const authContext = useMemo(
    () => ({
      toggleDrawer: async (data) => {
        dispatch({ type: 'TOGGLE_DRAWER' });
      },

    }),
    []
  );
  //check if mobile
  function checkDevice(mql) {
    setisLargeDisplay(mql.matches)
  }
  return (
    <AuthContext.Provider value={authContext}>
    <ScrollToTop/>

      {!isLargeDisplay && <SideBar drawerState={state.openDrawer} />}
      {isLargeDisplay && <HeaderLarge />}
      <Outlet />
      {!isLargeDisplay && <BottomNavigator />}
      {
        isLargeDisplay &&
        <FooterComponent />
      }
    </AuthContext.Provider>

  )
}